<template>
  <div>
    <Modal :options="{width: '40vw', type: 'danger'}" @close="$emit('close')">
      <div class="title">Marcar como vendida</div>
      <div class="body">
        <p class="text-center">Estas por marcar como vendida la propiedad  con número de fideicomiso:
            <strong>{{ garantia.fideicomiso }}</strong>,
            esta acción no se puede deshacer.<br/>¿Deseas continuar?
         </p>
      </div>
      <div class="footer">
        <div class="row">
          <div class="col-sm-12 text-right">
            <button class="btn btn-primary mr-2" @click="marcar_como_vendida">Marcar como vendida</button>
            <button class="btn btn-danger" @click="$emit('close')">Cancelar</button>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script type="text/javascript">

import Modal from '@/components/Modal'
import apiActivos from "@/apps/garantias/api/activos";

  export default {
    components: {
      Modal
    }
    ,props: {
      garantia: {
        type: Object,
        required:true,
      }
    }
    ,methods: {
      async marcar_como_vendida() {
        try {
          this.garantia.estatus = 2;
          await apiActivos.editar_garantia(this.garantia.id, this.garantia);
          this.$helper.showMessage('Garantía vendida','¡Felicidades!','success');

          this.$emit('vendida');
          this.$emit('close');
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
    }
  }
</script>